

















































import {
  Component,
  Vue,
  Prop,
  Emit,
} from 'vue-property-decorator';

@Component
export default class TransactionDialog extends Vue {
  @Prop({ type: Boolean, required: true }) open: boolean|undefined;

  get dialog() {
    return this.open;
  }

  @Emit('close-dialog')
  // eslint-disable-next-line class-methods-use-this
  close() {
    return false;
  }
}
